body {
  background: #FBFBFE
}

a {
  color: #4069A9;
}

.main-sidebar {
  box-shadow: none;
  border-right: 1px solid #EEEFF1;
}

.main-navbar {
  box-shadow: none !important;
  border-bottom: 1px solid #EEEFF1;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .page-header, .page-subtitle {
  color: #3D3C3E;
}

.text-fiord-blue {
  color: #3D3C3E !important;
}

.main-sidebar .nav .nav-item .nav-link.active, .main-sidebar .nav .nav-item .nav-link:hover, .main-sidebar .nav .nav-item.active, .main-sidebar .nav .nav-item:hover {
  box-shadow: inset 0.1875rem 0 0 #4069A9;
  background-color: #F6F9FE;
  color: #4069A9;
}

.main-sidebar .nav .nav-item .nav-link.active i, .main-sidebar .nav .nav-item .nav-link:hover i, .main-sidebar .nav .nav-item.active i, .main-sidebar .nav .nav-item:hover i {
  color: #4069A9;
}

.card, .card-small {
  box-shadow: none;
  border-radius: 0px;
  border: 1px solid #EEEFF1;
}

.card-post__image {
  border-radius: 0px;
}

.subnav-item {
  background-color: #EEEEEE;
}

.react-flags-select-bootstrap {
  background-color: #FFFFFF;
  padding-bottom: 0px !important;
}

.header-subtext {
  margin: 0px;
  margin-top: 8px;
  color: #BCBDC0;
  font-size: 12px;
}
